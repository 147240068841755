@import '../../../styles/variable.scss';

#setYtHelperAssetsModel .modal-footer {
    gap: 16px;
    width: calc(100% - 16px);

    button,
    button:hover,
    button:focus {
        background-color: $primary-color;
        color: white;
        text-transform: uppercase;
        font-size: 13px;
        height: 35px;
    }
    button:disabled {
        background-color: #dfdfdf;
        box-shadow: none;
        color: #9f9f9f;
        padding: 4px 15px;
        border-radius: 6px;
        border: 1px solid transparent;
    }
}

@media (max-width:768px)
{
    .thumbnails-link {
        width: 100% !important;
        margin-left: 0 !important;
    }

    #setYtHelperAssetsModel .modal-footer {
        gap: 8px;
        width: 100%;
    }
}
