@import "../../../../../styles/variable.scss";

.leftChatWrapper {
  background: #EEF1F4;
  border-radius: 16px 16px 16px 0px;
  width: 50%;
}

.rightChatWrapper {
  background-color: #E8F2FE;
  border-radius: 16px 16px 16px 0px;
  width: 50%;
  box-shadow: 0px 0px 0px 1px rgba(219, 222, 223, 0.44);
  border-radius: 16px 16px 0px 16px;
}

.leftChat {
  text-align: left;

  .clientName {
    color: $primary-color;
    margin-bottom: 2px;
  }
}

.rightChat {
  text-align: -webkit-right;
}

.optionAndCommentContainer {
  margin-top: 12px;
  .rowWrapRight {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .commentAndTimeContainer {
      color: $primary-color;

      .commentMessage {
        .chatMessage {
          background: #E8F2FE;
          padding: 16px;
          color: #0E1114;
          box-shadow: 0px 0px 0px 1px rgba(219, 222, 223, 0.44);
          border-radius: 16px 16px 0px 16px;
          margin: 2px 0;
        }
        .chatMessageForNotReplied {
          border: solid 1px red;
        }
        span {
          color: #616C76;
          font-size: 12px;
        }
      }     
    }
  }
  .rowWrapLeft {
    display: flex;
    gap: 15px;

    .commentAndTimeContainer {
      color: $primary-color;

      .commentMessage {
        .chatMessage {
          background: #EEF1F4;
          padding: 16px;
          color: #0E1114;
          border-radius: 16px 16px 16px 0px;
          margin: 2px 0;
        }
        .chatMessageForNotReplied {
          border: solid 1px red;
        }
        span {
          color: #616C76;
          font-size: 12px;
        }
      }
    }
  }

  .lightGreyColor {
    .commentAndTimeContainer {
      color: lightgray;
      .commentMessage {
        .chatMessage {
          background: #F0F0F0;
        }
      }
    }
  }
}

.chatHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ChatDescription{
    font-size: 13px;
    color: gray;
  }
}
.sendButton, .sendButton:focus {
  padding: 0 28px;
}
.sendButton:hover {
  background-color: transparent;
}

.chatDateDiv {
  text-align: center; margin: 14px 0;
  .chatDate {
    background-color: #8FA0AF;
    border-radius: 999px;
    padding: 2px 8px;
    color: #FFFFFF;
    font-size: 13px;
  }
}

.threeDotsIcon {
  width: 20px;
  height: 20px;
}

.editAndDeleteIconWrapper {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  justify-content: flex-end;
  align-items: center;

  .editIcon, .deleteIcon {
    font-size: 18px !important;
    cursor: pointer;
  }
  .deleteIcon {
    color: $primary-color !important;
  }
  .replyIcon {
    color: #616C76;
    margin-right: 4px;
    cursor: pointer;
  }
  .markAsDoneIcon, .thumbsUpIcon {
    cursor: pointer;
  }
}

.customerTeamChatBtnContainer {
  display: flex;
  gap: 10px;

  .customerChatBtn, .teamChatBtn {
    background-color: $primary-color;
    text-transform: capitalize;
    line-height: 0;
    width: max-content;
  }
  .customerChatBtn:focus, .teamChatBtn:focus {
    line-height: 36px;
    padding: 0 16px;
  }
}

.btnsAndSelectBoxWrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width:768px)
{
  .clientName b {
    font-size: 12px;
  }

  .chatHeaderRow {
    .cardTitle {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
  .customerTeamChatBtnContainer {
    .customerChatBtn,
    .teamChatBtn {
      font-size: 12px;
    }
  }

  .chatDateDiv {
    .chatDate {
      font-size: 12px;
    }
  }

  .btnsAndSelectBoxOuterWrapper {
    width: 100%;
  }

  .btnsAndSelectBoxWrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
  }

  .selectUserType {
    width: 100%;
    font-size: 12px;
  }

  .chatAnnotationLabel {
    top: 0;
    width: 85%;
  }
}
