.addTitleField, .addMessageField {
    font-size: 14px !important;
}

@media (max-width:768px)
{
  .greyFontColor {
    font-size: 14px;

    span {
      font-weight: 600;
      color: #000;
    }
  }

  .anchorTag {
    word-break: break-all;
  }

  .mobileMode {
    display: block;
  }
}
