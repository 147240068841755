@import '../../../../styles/variable.scss';

.container {
  width: 100%;
  padding-right: 30px;
  background-color: #F5F5F5 !important;

  .leftSection {
    .videoTabs {
      display: block;
      margin-bottom: 15px;
    }
  }

  .previewWrapper {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  .videoPreview {
    margin-top: 1.3rem;
    .videoPlayer {
      width: 100%;
      max-height: 60vh;
      height: 60vh;
      border: 1px solid black;
      border-radius: 5px;
    }
    .videoPlayerMobile {
      height: inherit !important;
    }
    .unSupportedVideo{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-height: 60vh;
      height: 60vh;
      border: 1px solid black;
      border-radius: 5px;
      background-color: rgba(0,0,0,0.5);
      color: white;
      font-size: 25px;
      margin: auto;
    }
  }
 

  .rightSection {
    .chatBoxWrapper {
      margin-left: 3rem;
      .customerChatBtn {
        background-color: #E1E1E1;
        color: black;
      }
      .teamChatBtn {
        background-color: $primary-color;
      }
      .btnsWrapper {
        gap: 10px;
      }
    }
  
  }
}

@media (max-width:768px)
{
  .container {
    padding-right: 8px;
    .wrapper {
      display: block;
      .leftSection {
        max-width: 100%;
        .videoTabs {
          margin-bottom: 0;
          padding: 8px;
        }
      }
  
      .rightSection {
        max-width: 100%;
        .chatBoxWrapper {
          margin-left: 0;
        }
      }
  
      .previewWrapper {
        .cardTitle {
          font-size: 14px;
        }
      }

      .videoPreview {
        margin-top: 22px;
      }  
    }
  }
}

@media (max-width:1024px)
{
  .container {
    padding-right: 8px;
    .wrapper {
      display: block;
      .leftSection {
        max-width: 100%;
        .videoTabs {
          margin-bottom: 0;
          padding: 8px;
        }
      }
  
      .rightSection {
        max-width: 100%;
        .chatBoxWrapper {
          margin-left: 0;
        }
      }
    }
  }
}
