@import '../../../styles/variable.scss';

.cardStyle {
    cursor: pointer;
}

.selectedCountAndCheckboxWrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    button, button:hover, button:focus {
        background-color: $primary-color;
        font-size: 13px;
        padding: 4px 15px;
        border-radius: 6px;
        border: 1px solid transparent;
    }

    button:disabled {
        padding: 4px 15px;
        border-radius: 6px;
        border: 1px solid #999999;
    }

    .checkboxWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

.avatarStyle {
    width: 48px;
    height: 48px;
    font-size: 14px;
    align-items: center;
    display: flex;
    background-color: $primary-color;
    flex-shrink: 0;
    
    img {
        display: flex;
    }
}

.cardContentWrapper {
    display: flex;
    gap: 12px;
}
.emptyCardWrapper {
    width: 100%;
    margin-top: 42px;
}
.notificationRow {
    flex-direction: column !important;
    align-items: center !important;
    gap: 10px !important;
    padding: 5px !important;
  }
  
  .notificationCol {
    width: 100% !important;
    flex: 100% !important;
    max-width: 100% !important;
    padding: 0px !important;
  }
  .divInsideAvatar {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
  
  .clientNameTime {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  
  .clientName {
    font-weight: 600;
    font-size: 16px;
    font-family: Poppins;
  }
  
  .date {
    font-family: Poppins;
    line-height: normal;
    font-size: 12px;
  }
  
  .message {
    font-size: 12px;
    color: #616161;
    font-family: Poppins;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .historyIcon{
    float: inline-end !important;
  }
  
.loader {
    width: 100%;
    text-align: center;
    margin-top: 42px;
}

.deskMode button {
    display: none;
}

@media (max-width:768px)
{
    .selectedCountAndCheckboxWrapper {
        justify-content: space-between;
        gap: 0;

        button, button:hover, button:focus {
            background-color: $primary-color !important;
            font-size: 13px;
        }
        button:disabled {
            font-size: 13px;
            padding: 2px 8px;
        }
        .markAsReadBtn,
        .markAsReadBtn:hover {
            padding: 2px 8px;
        }
    }

    .cardContentWrapper {
        flex-wrap: wrap;
        .divInsideAvatar {
            width: calc(100% - 44px);
        }
    }

    .avatarStyle {
        width: 30px;
        height: 30px;
        font-size: 12px;
        align-items: center;
        display: flex;
    }
    .clientName {
        font-size: 14px;
    }
    .message {
        font-size: 12px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .date {
        font-size: 9px;
        flex-shrink: 0;
    }

    .emptyCardWrapper {
        padding: 0 8px;
    }

    .deskMode {
        display: block;

        button, button:hover, button:focus {
            background-color: $primary-color !important;
        }
    }
}
