@import '../../styles/variable.scss';

.list-group-item {
    .active {
        background-color: $primary-color;
    }
}

.notificationCards {
        .ant-card-body{
            padding: 15px !important;
        }
}
