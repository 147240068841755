@import '../../styles/variable.scss';
.startAndEndDateDiv {
  width: 100%;
}

.headingStyle {
  width: 100%;
  .colorPicker input {
    margin-top: 42px;
  }
}

.bodyTextAreaStyle {
  width: 100%;
  textarea {
    width: 100% !important;
  }
}

td .customButton{
    width: 30% !important;
    margin-right: 5px;
    justify-content: center;
    display: inline-flex;
    background-color: $primary-color;
    &:focus {
      background-color: $primary-color;
      display: inline-flex !important;
    }
    &:hover{
      background-color: $primary-color;
      box-shadow: none !important;
    }
}

.NoDataFound{
  text-align:center !important;
   font-weight: 500 !important;
   font-size: 15px !important;
}

@media (max-width:768px) {
  td.actionListButton {
    display: flex !important;
    i {
      margin: auto !important;
    }
  }
}
