@import '../../../styles/variable.scss';

.content {
    background-color: #F5F5F5 !important;
    padding-left: 2rem;
    padding-top: 0;
    margin-top: 4px;
    width: 100%;

    .cards {
      width: 98%;
      background-color: #FAFAFA;
      margin-bottom: 10px;
      cursor: pointer;
    }
    
    .showMoreBtnContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 2rem 0 5rem 0;

      button, button:hover, button:focus {
        background-color: $primary-color;
      }
    }
  }

.iconAndTextContainer {
  gap: 5px;
  align-items: center;
  display: flex;
}

.greyFontColor {
  color: #616161;
}


.loader {
  text-align: center;
}

.cardActivityRow {
  margin-bottom: 0;
}

.collapsibleStyle {
  width: 98%;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  
  .iconAndTitleDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.ratingTextStyle {
  color: #000000;
}

@media (max-width:768px)
{
  .content {
    padding-left: 8px;
    padding-top: 0;

    .showMoreBtnContainer {
      margin: 1rem 0 1rem 0;

      button, button:hover, button:focus {
        font-size: 12px;
        background: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
      }
    }
  }

  .headingWithCount {
    line-height: 26px;

  }

  strong {
    font-size: 12px;
  }

  .greyFontColor, .iconAndTextContainer {
    font-size: 10px;
  }

  .srtFileWrapper {
    align-items: center;
  }
}
