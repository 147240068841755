.notranslate.public-DraftEditor-content {
    border: 1px solid #E0E0E0;
    border-radius: 6px;
}

.voiceRecord div {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    button {
        align-items: center;
        display: flex;
    }
}

@media (max-width:768px)
{
    input:not([type]), input[type=text]:not(.browser-default),
    input[type=password]:not(.browser-default),
    input[type=email]:not(.browser-default),
    input[type=url]:not(.browser-default),
    input[type=time]:not(.browser-default),
    input[type=date]:not(.browser-default),
    input[type=datetime]:not(.browser-default),
    input[type=datetime-local]:not(.browser-default),
    input[type=tel]:not(.browser-default),
    input[type=number]:not(.browser-default),
    input[type=search]:not(.browser-default),
    textarea.materialize-textarea {
        font-size: 12px;
    }

    i.material-icons.prefix {
        display: none;
    }
    .selectInput .select-wrapper, #txt_card_title, #txt_dbx_link, .labele_title .wrapperClassName, #otherInfo {
        width: 100% !important;
        margin-left: 0 !important;
    }
    .col.s12.center-align {
        display: flex;
        align-items: center;
    }
    .voiceRecord div button {
        font-size: 12px;
        align-items: center;
        display: flex;
    }
    input#create-for-customer {
        width: 100% !important;
        margin-left: 0 !important;
    }
}
