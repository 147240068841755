@import '../../../styles/variable.scss';

.container {
  text-align: center;

  button {
    margin-left: 12px;
  }
}

.outerDivWrapper {
  background-color: #F5F5F5;
  width: 40%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  position: relative;

  .channelStatsHeading {
    font-size: 20px;
    text-align: left;
    padding-left: 12px;
    padding-top: 6px;
    font-weight: 600;
    color: $primary-color;
  }

  .loginButtonDiv {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    gap: 8%;
  }
  .disconnectBtnDiv {
    padding-bottom: 20px;
  }
}

.divWrapper {
  display: flex;
  width: 100%;
  padding: 0px 16px;
  justify-content: space-between;
  gap: 24px;
  border-radius: 6px;

  .ulHeadings {
    text-align: left;
    font-weight: 500;
    color: $primary-color;

    li {
      padding-bottom: 12px;
    }
  }
}

.selectBox {
  width: 220px;
  margin-top: 12px;
}

.iconAndAnalyticsWrapper {
  display: flex;
  gap: 24px;
}

.fallGraph {
  color: #fe0002;
}

.riseGraph {
  color: #0fec00;
}

.iconAndListIconWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.overlay {
  width: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}

.overlayHide {
  display: none;
}

.disconnectButton,
.disconnectButton:hover,
.disconnectButton:focus {
  background-color: $primary-color;
  padding: 0 16px;
}


@media (max-width:768px)
{
  .container {
    padding: 0 !important;
    font-size: 13px;
  }
}
