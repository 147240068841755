.video_request, .editing, .on_hold, .qa {
    font-size: 14px;
    padding: 4px 30px;
    border: 0;
    background: #fffbe6;
    color: #faad14;
}

.done, .repurpose_done {
    font-size: 14px;
    padding: 4px 30px;
    border: 0;
    background: #00C3171F;
}

.repurpose_editing {
    font-size: 16px;
    padding: 4px 30px;
    border: 0;
    color: #531dab;
    background: #f9f0ff;
}

.canceled {
    font-size: 16px;
    padding: 4px 30px;
    border: 0;
    color: rgba(0, 0, 0, 0.88);
    background: rgba(0,0,0,.05);
}

.ratingCommentTextOuterContainer {
    margin-top: 10px;
}

.ant-card-body {
    padding: 8px 24px 30px 24px !important;
}

.desktop-mode {
    display: none;
}
.collapsibleCards{
    .ant-card-body{
        padding: 24px !important;
    }
    .ant-card-meta-title p{
        margin: 0px;
    }
    .ant-card-meta-description{
        display: flex;
        .ant-space-vertical{
            row-gap: 0px;
        }
    }
}

@media (max-width:768px)
{
    .ant-card-body {
        padding: 2px 0px 14px 10px !important;
    }
    .ant-card-meta-title {
        margin-bottom: 0;
    }
    .roundDot-video_request, .roundDot-editing, .roundDot-on_hold, .roundDot-qa {
        background-color: #faad14;
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
    .roundDot-done, .roundDot-repurpose_done {
        background-color: #00C317;
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }
    .roundDot-repurpose_editing {
        background-color: #531dab;
        width: 9px;
        height: 9px;
        border-radius: 50%;  
    }
    .roundDot-canceled {
        background-color: rgba(0, 0, 0, 0.88);
        width: 9px;
        height: 9px;
        border-radius: 50%;  
    }

    .desktop-mode {
        display: flex;
    }
    
    .collapsibleCards{
        .ant-card-body{
            padding: 10px 15px !important;
        }
        .ant-card-meta-description{
            .ant-space-vertical{
                row-gap: 8px;
            }
        }
    }
}
