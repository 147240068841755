.deskMode {
  display: block;
}

.deskModeInline {
  display: inline-block;
}

.mobileMode {
  display: none;
}

@media (max-width:768px) {
  .cardContainer {
    margin: 8px 8px !important;

    .subtitlesCol label span,
    .ytHelperPlanCol label span,
    .emailPref label,
    .emailPref label span {
      font-size: 12px !important;
      margin-left: 0;
    }

    .youtubeHelperHeading h4 {
      font-size: 25px;
    }

    .ytHelperMessage h6 {
      font-size: 15px;
    }

    .buttonContainer {
      display: flex;
      width: 100%;

      button {
        height: 36px !important;
        font-size: 12px !important;
        padding: 0 !important;
      }
    }

    .deskMode, .deskModeInline {
      display: none;
    }
    
    .mobileMode {
      display: block;
    }

    .photoPreferenceStyle {
      width: 100%;
      float: none;
    }

    .thumbnailWrapper {
      display: grid;

      .thumbnailLinks {
        width: 100%;

        div button {
          padding: 0 6px;
          height: 35px;
          line-height: 35px;
          width: 29px;
        }
      }
      
      .thumbnailFile {
        width: 100%;

        .thumbnailFileWrap {
          .thumbnailPrevBtn {
            margin-bottom: 20px;

            button {
              font-size: 12px;
              padding: 0 12px;
            }
          }
        }
      }
    }

    .descriptionWrap div {
      button {
        padding: 0 6px;
        height: 35px;
        line-height: 35px;
        width: 29px !important;
      }
    }

    .imgAndBtnWrapper {
      display: flex !important;
      width: 100% !important;
      align-items: center;
      gap: 10px !important;
      margin-bottom: 10px;

      button {
        width: 100% !important;
        font-size: 10px !important;
        padding: 0;
      }
    }

    .colStyle {
      margin-bottom: 0;
    }

    .textInput label {
      font-size: 12px !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    .selectInput label {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
