.mainContainer {
    background-color: #fff;
    padding-left: 280px;

    .PrivacyContentContainer {
        width: 80%;
    }

    h5 {
        font-weight: bold;
    }

    p {
        font-size: 16px;
        font-style: inherit;
        font-weight: inherit;
        vertical-align: baseline;
        line-height: 24px;
    }

    span {
        font-weight: 1000;
    }

    .headerContainer {
        border-bottom-width: 1px;
        border-bottom-color: rgba(0, 0, 0, 0.17);
        border-bottom-style: solid;
        width: 80%;

        img {
            width: 15% !important;
        }
    }
}