.cardVideoType {
  .ant-tabs-nav {
    .ant-tabs-nav-wrap{
      justify-content: center;
      margin: 10px;
    }
  } 
}
@media (max-width:768px)
{
  .tab-vid-inn {
    font-size: 13px;
    padding: 6px 22px;
  }

  .ant-select-selection-item {
    font-size: 12px;
  }
}

@media (max-width:1024px)
{
  .tab-vid-inn {
    font-size: 13px;
    padding: 6px 15px;
  }
}
