@media (max-width:768px) {
  .profile-cover .input-field.col label {
    left: 0;
    font-size: 12px;
  }

  .profile-cover .profile-box .right-box {
    width: 100%;
  }
  
  .profile-cover textarea.materialize-textarea {
    width: 100% !important;
  }

  .profile-cover .card-action button.waves-effect {
    width: 100% !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .profile-cover .profile-box .left-box, .profile-cover .profile-box .right-box {
    margin-right: 0;
  }

  .input-field {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .custom-input-profile .input-field {
    min-height: 0 !important;
  }

  #profile-portal-vid {
    margin-top: 14px !important;
    width: 100% !important;
  }
}
