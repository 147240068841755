.container {
  .imgDiv {
    text-align: center;

      img {
      position: relative;
      height: 95px;
      margin-top: 10px;
    }
  }

  .videoCardContainer {
    margin-top: 40px;
    text-align: -webkit-center;

    .videoCardInnerContainer {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      max-width: 60%;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .collapsibleContainer {
    .collapsibleStyle {
      .iconAndTitleDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .listStyle {
        list-style-type: disc;
        font-weight: bold;
      }
    }
  }
}

@media (max-width:768px) {
  .container {
    .innerContainer {
      padding: 8px 0px 0px;
      
      .imgDiv {
        text-align: left;
    
          img {
          position: relative;
          height: 40px;
          margin-top: 0;
        }
      }

      div .inputAndDivWrapper {
        width: 100%;
      }
  
      .textInputDiv {
        max-width: 90% !important;
        margin-left: 0 !important;
      }
    }

    .videoCardContainer {
      margin-top: 22px;
      padding: 0px 12px;

      .videoCardInnerContainer {
        max-width: 100%;
        justify-content: normal;
        flex-wrap: nowrap;
        overflow-x: auto;

        .customCard {
          width: calc(100% - 15px) !important;
          flex-shrink: 0;
        }
      }
    }

    .collapsibleContainer {
      padding: 0;

      .collapsibleStyle {
        width: calc(100% - 22px);
      }
    }
  }
}
