@import '../../../../styles/variable.scss';
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 30px;

  .leftSection {
    display: flex;
    gap: 45px;

    .iconsStyle {
      width: 38px;
      height: 38px;
    }
  }

  .rightSection {
    display: flex;
    gap: 13px;

    .revisionBtn,
    .revisionBtn:hover {
      background-color: $primary-color;
      padding: 0 44px !important;
    }

    .readyForUploadBtn,
    .readyForUploadBtn:hover,
    .readyForUploadBtn:focus {
      background-color: #00C317;
    }

    .archiveBtn,
    .archiveBtn:hover,
    .pauseBtn,
    .pauseBtn:hover {
      background-color: #E1E1E1;
      color: black;
    }
  }
}

.iconsAndText, .colStyle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.videoDownloadIcon {
  padding-left: 4px;
  padding-right: 4px;
  max-width: fit-content;
}

.greyFontColor {
  color: #616161;
  font-size: 16px;
}

.containerForVideoDescription {
  padding-right: 4rem;

  p {
    margin: 0;
  }
}

.headingFontStyle {
  font-weight: 600;
  color: #000000;
  font-size: 16px;
}

.downloadBtn {
  background-color: #00C317;
  color: #fff;
}

.downloadLink {
  margin-left: 10px;
}

.loader {
  width: 100%;
  margin-top: 4rem;
}

.qaSelectBox {
  width: 170px;
}

.setDueDateStyle input {
  margin: 0;
  border-bottom: 0;
  box-shadow: 0;
}

.wrapperForLinkChange {
  display: flex;
  gap: 10px;
  align-items: center;

  .closeBtn,
  .saveBtn,
  .closeBtn:hover,
  .saveBtn:hover {
    background-color: $primary-color;
  }
}

.newLinkField {
  width: 700px !important;
}

.contentIdInfoButton,
.contentIdInfoButton:focus,
.contentIdInfoButton:hover  {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    outline: none;
    margin-left: 0px;
}
  
.contentIdInfoButton svg {
  width: 20px !important;
  height: 25px;
  margin-top: 4px;
  fill: $primary-color;
}
.mobileMode {
  display: none;
}

.downloadBtnText,
.downloadBtnText:hover,
.downloadBtnText:focus {
  background-color: #00C317 !important;
  padding: 0 44px !important;
}

@media (max-width:768px)
{
  .container {
    display: block;

    .leftSection {
      display: inline-grid;
      gap: 0;

      .iconsStyle {
        width: 29px;
        height: 29px;
      }

      .marginInMobile {
        margin-top: -6px;
      }
    }

    .rightSection {
      margin-top: 19px;

      .revisionBtn, .revisionBtn:hover {
        height: 34px;
        font-size: 13px;
        width: 55%;
      }

      .pauseBtn, .pauseBtn:hover {
        height: 34px;
        padding: 0;
        width: 15%;
        span svg {
          font-size: 11px;
        }
      }

      .downloadBtnIcon {
        background-color: #00C317;
        height: 34px;
        width: 34px;
      }

      .archiveBtn, .archiveBtn:hover {
        height: 34px;
        width: 15%;

        img {
          width: 11px;
          height: 11px;
        }
      }

      .readyForUploadBtn {
        background-color: #00C317;
        font-size: 12px;
        height: 34px;
      }
    }

  .mobileMarkDoneBtn {
    .readyForUploadBtn {
      background-color: #00C317;
      font-size: 13px;
      height: 34px;
      margin-top: 11px;
      width: 55%;
    }
  }
  }
  .greyFontColor {
    font-size: 12px;

    span {
      font-weight: 600;
      color: #000;
    }
  }

  .iconsStyle {
    width: 29px;
    height: 29px;
  }

  .newLinkField {
    width: 100% !important;
    margin-left: 8px !important;
    margin-right: 10px !important
  }

  .setDueDateStyle {
    width: 80%;
    input {
      font-size: 13px !important;
    }
  }

  .wrapperForLinkChange {
    div {
      width: 68% !important;
    }
    .closeBtn,
    .saveBtn,
    .closeBtn:hover,
    .saveBtn:hover {
      width: 38px !important;
      height: 34px !important;
      font-size: 13px !important;
    }
  }

  .avatarStyle {
    width: 26px;
    height: 26px;
    font-size: 12px;
    align-items: center;
    display: flex;
  }
  
  .downloadLink .downloadBtn,
  .editOutlinedBtn {
    font-size: 11px !important;
    width: 34px !important;
    height: 30px !important;
  }

  .downloadLink {
    margin-left: 0px;
  }

  .headingFontStyle {
    font-size: 12px;
    width: 50%;
  }
  .deskMode {
    display: none;
  }
  .mobileMode {
    display: block;
    span {
      margin-left: auto;
    }
  }
  .fontTwelve {
    font-size: 12px;
  }

  .assignedToWrapper {
    justify-content: space-between;
  }

  .srtFileNA {
    display: flex;
    align-items: center;
  }
}

@media (max-width:1024px) 
{
  .container {
    display: block;

    .leftSection {
      gap: 10px;
      column-gap: 40px;
      flex-wrap: wrap;
    }
  }
}
