.colorPickerDiv .input-field.col.s2 {
  margin: -10px 0px;
}

.holidayCardTitle .card-content span.card-title {
  margin-bottom: 5px !important;
}

@media (max-width:768px) {
  .announcementsForm {
    .input-field {
      width: 100% !important;
    }

    .saveButton {
      width: auto !important;
      min-width: 150px !important;
    }
  }

  .announcementsListTable {
    .card-content {
      overflow-x: auto;
    }
  }

  .holidayListTable {
    .card-content {
      overflow-x: auto;
    }
  }
}
