.container {
  .imgDiv {
    text-align: center;

      img {
      position: relative;
      height: 95px;
      margin-top: 10px;
    }
  }

  .videoCardContainer {
    margin-top: 40px;
    text-align: -webkit-center;

    .videoCardInnerContainer {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      max-width: 60%;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .custom-collapsible .collapsible-header {
    padding: 0px 0px 0px 12px;
  }

  .custom-collapsible li.active .collapsible-header i {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media (max-width:768px) {
  .custom-collapsible .collapsible-header {
    font-size: 16px;
  }

  .custom-collapsible .collapsible-body {
    font-size: 13px;

    ol {
      padding: 0 6px 0px 16px !important;
    }

    ul {
      padding: 0 6px 0 22px !important;
    }
  }
}
