@import '../../../styles/variable.scss';

.header {
    background-color: #F5F5F5 !important;
    display: flex;
    width: 100%;
    padding: 0px 35px;
    padding-inline: 32px !important;
    align-items: center;

    .ulTabs {
      display: inline-flex;
      align-items: center;

      li {
        margin-right: 25px;
        font-size: 16px;
        color: #616161;
        cursor: pointer;
        flex-shrink:0;
      }
    }

    .ulTabs li:hover, .activeTab {
      color: $primary-color !important;
      text-decoration: underline;
    }

    .rightSection {
      margin-left: auto;

      .viewAsStyle {
        width: 150px;
        height: 40px;
        input {
          border-bottom: 0 !important;
          box-shadow: none !important;
        }
      }

      .datePicker input {
        margin: unset;
        width: 72px;
        border-bottom: unset !important;
      }

  
      .selectBox {
        width: 200px;
      }
  
      .searchInput {
        vertical-align: middle;

        input {
          border-bottom: unset;
          margin: unset;
          font-size: 14px;
          height: auto;
          padding: 6px 20px;
        }
      }

      .createCardBtn {
        background-color: $primary-color;
        padding: 6px 20px !important;
        height: 44px;
      }
    }
  }

  .timezoneDiv {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 6px 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

@media (max-width:1694px)
{
  .header {
    background-color: #F5F5F5 !important;
    display: flex;
    width: 100%;
    padding: 0px 35px;
    padding-inline: 32px !important;
    height: auto !important;

    .leftSection {
      display: flex;
      gap: 20px;

      .cardsTitle {
        margin: 0;
      }
    }
  }
}

@media (max-width:2138px)
{
  .header {
    background-color: #F5F5F5 !important;
    display: grid;
    width: 100%;
    padding: 0px 35px;
    padding-inline: 32px !important;
    height: auto !important;

    .leftSection {
      display: flex;
      gap: 20px;

      .cardsTitle {
        margin: 0;
      }
    }
  }
}

@media (max-width:768px)
{
  .header {
    display: block;
    margin-bottom: 10px;
    padding-inline: 8px 8px !important;
    height: auto !important;

    .leftSection {
      line-height: 0px;
      margin: 0;
      display: flex;

      .cardsTitle {
        font-size: 22px;
      }

      .viewsContainer {
        gap: 10px;
        .kanbanViewBtnMobile {
          background-color: #E1E1E1;
          color: black;
          align-items: center;
          display: flex;
          font-size: 12px;
        }
        .listViewBtnMobile {
          background-color: $primary-color;
          color: white;
          align-items: center;
          display: flex;
          font-size: 12px;
        }
      }
    }

    .ulTabs {
      width: 100%;
      overflow-x: auto;
      height: 26px;
      margin-block-start: 8px;
      margin-block-end: 4px;

      li {
        font-size: 12px;
        margin-right: 18px;
      }
    }
    .ulTabs::-webkit-scrollbar {
      height: 0;
    }

    .rightSection {
      width: 100%;
      overflow-x: scroll;

      .spaceWrapper {
        display: flex;
        justify-content: space-between;
      }

      .viewAsStyle {
        width: 100%;
        font-size: 11px;
        height: 30px;
      }

      .datePicker {
        padding: 8px 2px 16px;
        background: none;
        border: 0;
        font-size: 22px;
      }

      .datePicker input {
        margin: unset;
        display: none;
        width: 72px;
        border-bottom: unset !important;
      }

      .searchInput {
        width: 100%;

        input {
          font-size: 11px;
        }

        span span button {
          height: 32px;
        }
      }

      .createCardBtn {
        width: 55px;
        height: 30px;
        font-size: 12px;
      }
    }
  }
  .desktopMode {
    display: none;
  }

  .timezoneDiv {
    padding: 4px 0;
    justify-content: center;
    margin-top: 10px;
    height: 32px;
    i {
      font-size: 20px;
    }
    time {
      font-size: 12px;
    }
  }
}
