@import '../../../../styles/variable.scss';

.cards {
  width: 98%;
  background-color: #FAFAFA;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 12px;

  .cardMetaContainer {
    display: flex;
    width: 100%;

    .cardListDescription {
      align-items: center;
      width: 100%;
      gap: 20px;

      .cardListTitle {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 20px;

        p {
        white-space: pre-line;
        word-break: break-all;
        }
      }

      .spaceContainer {
        .avatarIconGroup {
          gap: 20px !important;
          
          .iconAndTextOuterContainer {
            gap: 20px;
            align-items: center;
            display: flex;
          }
  
          .iconAndTextContainer {
            gap: 5px;
            align-items: center;
            display: flex;
          }
          .ratingCommentTextContainer {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: -0.75rem !important;
            margin-right: -0.75rem;
            margin-bottom: 1.25rem;

            svg {
              margin-right: 6px;
              width: 44px;
              height: 20px;
              fill: #2d2d2d;
              position: relative;
              top: 6px;
            }
          }
        }
      }

      .greyFontColor {
        color: #616161;
      }
    }

    .optionIconWrapper {
      margin-top: 19px;
      height: fit-content;
      align-self: center;
      margin-left: auto;
    }
  } 
}

.mapIcons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cardActivityRow {
  margin-bottom: 0;
  display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .activityStatusBtn, button:focus {
      background-color: $primary-color;
      cursor: pointer;
      width: max-content;
      display: block;
      padding: 6px 12px;
      border-radius: 4px;
      border: 0;
    
      strong {
        color: #FFF !important;
        display: flex;
        align-items: center;
        justify-content: center; 
         svg {
           margin-right: 9px;
           font-size: 15px;
         }
      }
    }
    
}

.deskMode {
  display: none;
}

.iconAndTextOuterContainer {
  gap: 20px;
  align-items: center;
  display: flex;
}

.iconAndTextContainer {
  gap: 5px;
  align-items: center;
  display: flex;
}

.mobileMode {
  display: none;
}

@media (max-width:768px)
{
  .cards {
    width: 98%;

    .cardMetaContainer {
      .cardListDescription {
        gap: 0px;
        width: calc(100% - 30px);
        .cardListTitle {
          font-size: 14px;

          .roundDotWrapper {
            width: 100%;
            display: flex;
            align-items: baseline;
            gap: 8px;
          }

          p {
            margin: 13px 0px 8px 0px;
            white-space: nowrap;
            flex-shrink: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 18px);
            font-size: 12px;
          }
          
          span {
            display: none;
          }
        }
        .spaceContainer {
          .avatarIconGroup {
            gap: 6px !important;
  
            .avatarStyles {
              width: 25px;
              height: 25px;
              line-height: 25px;
            }
  
            .iconAndTextOuterContainer {
              gap: 0px;
              align-items: center;
              display: flex;
            }
  
            .iconAndTextContainer {
              gap: 5px;
              align-items: center;
              display: flex;
            }
            .deskMode {
              display: none;
            }
          } 
        }
      }
      .optionIconWrapper img {
        width: 23px;
      }
    }
  }

  .activityStatusBtn, button:focus {
    strong {
      font-size: 12px;
    }
  }

  .greyFontColor {
    font-size: 10px;
  }

  .icon svg {
    width: 10px;
    height: 10px;
  }

  .mobileMode {
    display: flex;
    flex-wrap: wrap;
  }
}
