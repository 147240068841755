.greyFontColor {
  color: #616161;
  font-size: 16px;
}

.containerForVideoDescription {
  padding-right: 4rem;

  p {
    margin: 0;
  }
}

@media (max-width:768px)
{
  .greyFontColor {
    font-size: 12px;

    span {
      font-weight: 600;
      color: #000;
    }
  }
}
