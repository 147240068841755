@import '../../../styles/variable.scss';

i.ant-spin-dot-item {
    background-color: $primary-color !important;
}

.ant-dropdown-menu-item {
    padding: 7px 12px !important;
}

.mobile-mode {
    display: none;
}
.desk-mode {
    display: flex;
}

.cardListCollapsible .collapsible-header {
    background-color: rgba(172, 50, 45, 0.2);
    border-radius: 6px;
}

.cardListCollapsible .collapsible-body {
    padding: 0;
    border-bottom: 0;

    .collapsibleCards {
        width: 100%;
    }
}

.cardListCollapsible li.active {
    .customIcon::after {
        width: 35px;
        height: 35px;
        text-align: center;
        background-color: white;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 27px;
        content: "-";
        color: $primary-color;
    }

    .customIcon::before {
        display: none;
    }
}

.cardListCollapsible li {
    .customIcon::before {
        width: 35px;
        height: 35px;
        text-align: center;
        background-color: white;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 27px;
        content: "+";
        color: $primary-color;
      }
}
.tab-content-listview {
    strong {
        display: flex !important;
    }
}

@media (max-width:768px)
{
    .mobile-mode {
        display: flex;
    }
    .desk-mode {
        display: none;
    }

    .icon {
        width: 10px;
        height: 10px;
    }

    #qa-done-link,
    #txt-done-link,
    #txt-content-id,
    .txt-rp-done-link-style,
    #thumbnails-link,
    #snippet-link,
    #unlisted-link {
        width: 100% !important;
        margin-left: 0 !important;
        font-size: 12px !important;
    }

    .cardMovementModal .modal-content .input-field label {
        margin-left: 0;
        font-size: 12px;
    }

    .cardMovementModal .modal-content .input-field label.active {
        left: 0;
        top: 2px;
    }

    .cardMovementModal .modal-content h4 {
        font-size: 18px;
    }

    .cardMovementModal .modal-footer .modal-close, .cardMovementModal .modal-footer .btn-primary {
        padding: 15px 18px;
        font-size: 12px;
    }

    .cardMovementModal .modal-content label span {
        height: 15px;
        line-height: 15px;
        font-size: 12px;
    }

    .cardListCollapsible li.active {
        .customIcon::after {
            width: 30px;
            height: 30px;
            font-size: 24px;
        }
    
        .customIcon::before {
            display: none;
        }
    }
    
    .cardListCollapsible li {
        .customIcon::before {
            width: 30px;
            height: 30px;
            font-size: 24px;
          }
    }
}
