.filtersDiv {
  padding: 12px 10px 4px 10px;
  margin-left: 30px;
  width: calc(100% - 60px);
}

.rowStyle {
  margin-bottom: 0;
}

.timezoneDiv {
  display: flex;
  align-items: center;
}

.searchBarAndBtns {
  margin-left: 30px;
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .topBarLeftStyle {
    width: fit-content;

    .refreshAndCreateBtn {
      display: flex;
      align-items: center;
    }
  }
}

@media (max-width:768px) {
  .filtersDiv {
    .col.date-filter-col {
      width: 100%;
    }
  }
  .searchBarAndBtns {
    display: block;
  }
}
