@import "../../../../styles/variable.scss";
.modalStyle button.ant-btn.css-dev-only-do-not-override-ixblex.ant-btn-primary {
    background-color: $primary-color;
}

.ant-btn.ant-btn-lg.ant-btn-icon-only:focus {
  display: inline-block;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width:768px)
{
  .selectBox {
    width: 100%;
  }
  
  .inside_linkg {
    padding: 0;
    display: inline-block;
  }
  
  .srtButton {
    font-size: 12px !important;
    line-height: 0 !important;
  }

  .ant-picker-dropdown {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-left: 34px;
  }

  .ant-picker-dropdown .ant-picker-datetime-panel {
    display: block;
  }

  .ant-select-selection-item {
    line-height: 28px !important;
  }

  .detailsWrapper .ant-space-item:nth-child(2) {
    margin-top: -6px;
  }

  .detailsTabDivider {
    margin: 12px 0;
  }

  .ant-tabs .ant-tabs-tab {
    font-size: 12px;
  }
}
