@import '../../../styles/variable.scss';
.viewsContainer {
  display: flex;
  gap: 20px;
  align-items: center;

  .activeViewBtn,
  .activeViewBtn button:hover {
    background-color: $primary-color !important;
    color: white;
    font-size: 14px;
  }
  .inActiveViewBtn,
  .inActiveViewBtn button:hover {
    background-color: #E1E1E1 !important;
    color: black !important;
    font-size: 14px;
  }
}

@media (max-width:768px)
{
  .viewsContainer {
    gap: 10px;

    button {
      background-color: $primary-color;
    }
  }
}
