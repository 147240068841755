@import '../../styles/variable.scss';

.startAndEndDateDiv {
    width: 100%;
}

.headingStyle {
    width: 100%;
}

.holidayTable {
    th {
        padding: 7px 5px !important;
    }

    td {
        text-align: center;
        padding: 7px 5px !important;

        .customButton {
            width: 50px !important;
            margin-right: 5px;
            justify-content: center;
            display: inline-flex;
            background-color: $primary-color;

            &:focus {
                background-color: $primary-color;
                display: inline-flex !important;
            }

            &:hover {
                background-color: $primary-color;
                box-shadow: none !important;
            }
        }
    }
}

.NoDataFound {
    text-align: center !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

@media (max-width:768px) {
    .holidayActionButton {
      display: flex !important;
    }
  }
