.ant-picker-cell-inner {
    text-align: center;
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 0;
}

.ant-layout-header {
  line-height: 0;
}
.datepicker-list-view {
  padding: 9px 20px;
  .ant-picker-active-bar {
    background: none !important;
   }
}
.searchInputStyle {
  .ant-input-search-button {
    padding: 6px 20px;
    height: 44px;
  }
}

@media (max-width:1694px)
{
  .ant-layout .ant-layout-header {
    line-height: 0;
  }
}



@media (max-width:768px)
{
  .ant-picker-range-separator, .ant-picker-active-bar {
    display: none;
    // font-size: 10px;
  }
  
  span.ant-select-selection-placeholder, span.anticon.anticon-down.ant-select-suffix, span.anticon.anticon-search {
    font-size: 11px;
  }

  .ant-select-selector, .ant-input-affix-wrapper {
    height: 32px !important;
  }
  
  .ant-picker-panels {
    flex-wrap: wrap !important;
  }

  .ant-picker-panel-container {
    margin-right: 8px;
  }
}
