@import '../../styles/variable.scss';

.homeLayout {
    background-color: #F5F5F5;

    .topHeader {
        margin: 20px 30px;
    }

    .container {
        margin: 0 30px;
        .cardsTitleDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
    
            .cardsTitle {
                margin: 4px 0 0 0;
                font-size: 28px;
            }
        }
    
        .filtersAndTotalCount {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 16px;

            .selectBoxWrapper {
                display: flex;
                gap: 12px;
                align-items: center;
    
                .viewAsStyle {
                    input {
                        border-bottom: 0 !important;
                        box-shadow: none !important;
                    }
                }

                .reloadIconDiv, .reloadIconDivAdmin {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .downloadDueCardsBtn,
                    .downloadDueCardsBtn:focus {
                        background-color: #ab322d;
                        color: #ffffff;
                        padding: 4px 15px;
                        width: 200px;
                        border-radius: 6px;
                        height: 30px;
                        border: 1px solid transparent;
                        display: flex;
                        align-items: center;
                        font-size: 17px;
                        padding: 0px 30px;
                    }
                    .resetIcon {
                        color: $primary-color;
                        cursor: pointer;
                        font-size: 20px;
                    }
                }
            }
        }
    
        .totalCountDiv {
            .totalCount {
                font-weight: 600;
            }
        }

        .customizeTable {
            thead tr th {
                background: rgba(171, 50, 45, 0.1);
                border: 1px solid $primary-color;
            }
        }
    }
}


@media (max-width:768px)
{
  .homeLayout {
    width: 100%;

    .topHeader {
      margin: 10px 8px;
    }

    .viewAsStyle {
        width: 100%;
        font-size: 11px;
        height: 30px;
    }

    .container {
        margin: 0 8px;
        .cardsTitleDiv {
            .cardsTitle {
              font-size: 22px;
          }
        }
        .filtersAndTotalCount {
            display: block;
            margin-bottom: 8px;

            .selectBoxWrapper {
                display: flex;
                align-items: center;
                overflow-x: scroll;
                overflow-y: hidden;
                justify-content: space-between;
                gap: 8px;
    
                .viewAsStyle {
                    width: 130px;
                    font-size: 11px;
                    height: 30px;
                }

                .datePickerDiv {
                    height: 30px;
                }
.dueCardExportButton {
    height: 30px !important;
}
                .reloadIconDiv {
                    position: absolute;
                    right: 8px;
                    top: 92px;

                    .resetIcon {
                        font-size: 18px;
                    }
                }

                .reloadIconDivAdmin {
                    position: absolute;
                    right: 8px;
                    top: 86px;

                    .resetIcon {
                        font-size: 18px;
                    }
                }
            }
        }

        .totalCountDiv {
            margin-top: 8px;
            .totalCount {
                font-size: 12px;
            }
        }

        .customizeTable {
            thead tr th {
               font-size: 11px;
            }
        }

        .tableDiv {
            margin-top: 8px;
            overflow-y: hidden;
            overflow-x: scroll;
            background-color: #ffffff;
        }
    }
  }
}

@media (max-width:375px)
{
    .homeLayout {
        .container {
            .filtersAndTotalCount {    
                .selectBoxWrapper {
                    .viewAsStyle {
                        width: 120px;
                    }
                }
            }
        }
      }
}

@media (max-width:360px)
{
    .homeLayout {
        .container {
            .filtersAndTotalCount {    
                .selectBoxWrapper {
                    .viewAsStyle {
                        width: 110px;
                    }
                }
            }
        }
      }
}
