@import '../../styles/variable.scss';

.Layout {
  background-color: #F5F5F5 !important;
  .topHeader {
      margin: 20px 30px;
  }

  .container {
    padding-left: 2rem;
    width: 100%;
    position: relative;
    z-index: 1;

    .titleAndIconWrapper {
      width: 50%;
      margin: auto;
      margin-top: 8px;

      .greyFontColor {
        color: #616161;
      }

      .arrowIconAndTextWrapper {
        display: flex;
        align-items: center;
        gap: 7px;
        padding: 0;
      }
  
      .arrowIconAndTextWrapper:focus {
        background-color: none;
      }
  
      .cardTitle {
        font-size: 25px;
        margin: 13px 0 23px 0;
      }
    }

    .fieldsWrapper {
      width: 50%;
      margin: auto;
      background-color: #fff;
      padding: 45px 40px;
      border: 1px solid #E8E8E8;
      box-shadow: 0px 0px 59px rgba(0, 0, 0, 0.03);
      margin-bottom: 2rem;
      border-radius: 6px;

      label {
        color: black;
      }

      .nextBtn, .createBtn {
        background-color: $primary-color;
        width: 120px;
        height: 35px;
        margin-top: 2rem;
      }
      .backBtn {
        background-color: #E1E1E1;
        color: black;
        width: 120px;
        height: 35px;
        margin-top: 2rem;
      }
      .btnWrapper {
        display: flex;
        gap: 17px;
      }
    }
  }

  .container:after {
    content: '';
    position: absolute;
    top: 0;
    height: 250px;
    left: 30px;
    right: 30px;
    background: rgba(172, 50, 45, 0.03);
    display: inline-block;
    z-index: -1;
    border-radius: 6px;
  }

}

@media (max-width:768px)
{
  .Layout {
    .topHeader {
      margin: 10px 8px;
    }
    .container {
      padding-left: 8px;
      padding-right: 8px;
      z-index: unset;

      .titleAndIconWrapper {
        width: 100%;
        margin-top: 0px;

        .cardTitle {
          font-size: 23px;
          margin: 6px 0 18px 0;
        }
      }

      .fieldsWrapper {
        width: 100%;
        padding: 23px 13px 29px 13px;
      }
    }
  }
  .mobileMode {
    display: none;
  }
}
