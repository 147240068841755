$color-placeholder: #00000055;
$color-red: #ab322d;
$color-red-darker: #7e2c2a;
$color-grey: #d9d9d9;
$color-darkgrey: #1f1f1f;
$color-green: #82b150;
$color-green-darker: #628147;
$color-white: #FFFFFF;
$color-black: #000000;
$color-light-grey: #D3D3D3; 
$primary-color: $color-red;
$secondary-color: $color-green;
$co-secondary-color: $color-light-grey;
$input-focus-color: $color-red;
$radio-border: 2px solid $color-green;
$disable-color: #cccccc;
$warning-color: red;
